import React from "react"
import WPImage from "../../images/wp.png"
import ARKImage from "../../images/ark-img.png"
import BlankImage from "../../images/blank.png"

const Project = () => {
  const projects = [
    {
      title: "WrappedPunks.com",
      desc: "Turn your CryptoPunks into ERC721",
      image: WPImage,
      link: "https://wrappedpunks.com/",
      boxStyle: "box-style-1",
      imageStyle: {
        borderRadius: "25px",
      },
      effect: "project-item-image",
      effectFull: "",
    },
    {
      title: "blank.art",
      desc: "Digital art marketplace powered by smart contracts",
      image: BlankImage,
      link: "http://blank.art/",
      boxStyle: "box-style-1",
      imageStyle: {
        width: "50%",
        height: "50%",
      },
      effect: "",
      effectFull: "project-item-image",
    },
    {
      title: "ark.gallery",
      desc: "The first DAO for Cryptopunks Collectors",
      image: ARKImage,
      link: "https://ark.gallery/",
      boxStyle: "box-style-2",
      imageStyle: {
        borderRadius: "27px",
      },
      effectFull: "",
      isFlash: true,
      effect: "project-item-image",
      flash: "project-item-image-light",
    },
    {
      title: "ark.gallery",
      desc: "The first DAO for Cryptopunks Collectors",
      image: ARKImage,
      link: "https://ark.gallery/",
      boxStyle: "box-style-2",
      imageStyle: {
        borderRadius: "27px",
      },
      effect: "",
      effectFull: "project-item-image-run",
    },
  ]

  const Item = ({ project }) => {
    return (
      <div>
        <a
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            background: "#FFFFFF",
            boxShadow: "6px 8px 30px rgba(0, 0, 0, 0.25)",
          }}
          href={project.link}
          target="_blank"
          className={`${project.boxStyle} ${project.effect}`}
        >
          {project.isFlash && (
            <div
              style={{
                backgroundImage: `url(${project.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                minWidth: "360px",
                minHeight: "360px",
                ...project.imageStyle,
              }}
              className={`iamge-style-2`}
            >
              <div className={project.flash} />
            </div>
          )}
          {!project.isFlash && (
            <div
              style={{
                backgroundImage: `url(${project.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                minWidth: "360px",
                minHeight: "360px",
                ...project.imageStyle,
              }}
              className={`iamge-style-2 ${project.effectFull}`}
            />
          )}
        </a>
        <div
          style={{
            marginTop: "20px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "#000",
              fontFamily: "Graphik SemiBold",
            }}
            target="_blank"
            href={project.link}
          >
            {project.title}
          </a>
        </div>
        <div
          style={{
            marginTop: "10px",
            fontSize: "18px",
          }}
        >
          {project.desc}
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "150px",
        marginBottom: "100px",
      }}
    >
      <div>
        <div
          style={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "30px",
            lineHeight: "150%",
            marginBottom: "200px",
            fontFamily: "Graphik SemiBold",
          }}
        >
          We partner with the best creators to produce applications powered by
          smart contracts and NFTs.
          <br />
          Our mission is to bring people together through art and new
          technologies.
        </div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: 300,
            lineHeight: "100%",
            letterSpacing: "-0.01em",
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "left",
          }}
        >
          Projects we have launched:
        </div>
        <div className="project-item">
          {projects.map(project => (
            <Item project={project} />
          ))}
          <div>
            <a
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                background: "#FFFFFF",
                boxShadow: "6px 8px 30px rgba(0, 0, 0, 0.25)",
              }}
              href="https://ark.gallery/"
              target="_blank"
              className="project-text box-style-2"
            >
              <div
                style={{
                  backgroundImage: `url(${WPImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100%",
                  minWidth: "360px",
                  minHeight: "360px",
                  borderRadius: "25px",
                }}
                className="project-item-image text-effect"
              >
                <div className={`text-button`}>
                  <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                    WrappedPunks.com
                  </div>
                  <div style={{ marginTop: "10px", fontSize: "18px" }}>
                    Turn your CryptoPunks into ERC721
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
