import React, { useRef, useState } from 'react';
import EffectProject from './EffectProject';
import Logo from '../../images/blank.svg';
import LogoWhite from '../../images/blank-white.svg';
import Discord  from '../../images/discord.svg';
import Twitter  from '../../images/twitter.svg';
import Instagram  from '../../images/insta.svg';
import useOnClickOutside from '../UseOnClickOutside';
import { isMobile } from 'react-device-detect';

const Home = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const projectRef = useRef('project');
  const teamRef = useRef('team');
  const contactRef = useRef('contact');
  const ref = useRef();

  const mobileMenu = (openMenu) => {
    const hamburger = document.querySelector(".hamburger");
    if (!openMenu) {
      hamburger.classList.remove("active");
      setShowMenu(false);
    } else {
      hamburger.classList.toggle("active");
      setShowMenu(true);
    }

    setOpenMobileMenu(openMenu)
  }

  const scrollToElement = (block) => {
    if (block === 'project') {
      projectRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (block === 'team') {
      teamRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (block === 'contact') {
      contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useOnClickOutside(ref, () => mobileMenu(false));

  return (
    <>
    <header className="header">
      <div style={{ display: 'flex', alignItems: 'center', zIndex: 99 }}>
        <img src={isMobile && showMenu ? LogoWhite : Logo}/>
      </div>
      {/* <div className="menu-desktop">
        <div style={{ cursor: 'pointer' }} onClick={() => scrollToElement('project')}>Projects</div>
        <div className="header-item" onClick={() => scrollToElement('team')}>Team</div>
        <div className="header-item" onClick={() => scrollToElement('contact')}>Contact</div>
      </div> */}
      <div ref={ref}>
        <div
          className="hamburger"
          onClick={() => mobileMenu(!openMobileMenu)}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className={`menu-bar ${showMenu ? 'showMenu-open' : 'showMenu-hide'}`}>
          <div class="TopNavMenu-popup-item-wrapper">
            <div
              class={`TopNavMenu-popup-item ${showMenu ? 'menu-item-show' : 'menu-item-hide'}`}
              onClick={() => {
                scrollToElement('project');
                mobileMenu(false);
              }}
            >
              Projects
            </div>
            <div
              class={`TopNavMenu-popup-item ${showMenu ? 'menu-item-show' : 'menu-item-hide'}`}
              onClick={() => {
                scrollToElement('team');
                mobileMenu(false);
              }}
            >
              Team
            </div>
            <div
              class={`TopNavMenu-popup-item contact-menu ${showMenu ? 'menu-item-show' : 'menu-item-hide'}`}
              onClick={() => {
                scrollToElement('contact');
                mobileMenu(false);
              }}
            >
              Contact
            </div>
          </div>
          <div className="contact-mobile">
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Contact us</div>
            <div>
            <a
              href="mailto:team@blanknetwork.com"
              style={{ textDecoration: "none", color: '#fff', fontSize: '23px' }}
              target="_blank"
            >
              team@blanknetwork.com
            </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/* <div className="menu-mobile" style={openMobileMenu ? {top: '110px'} : {top: '-200px'}}>
      <div className="menu-mobile-item" onClick={() => {scrollToElement('project'); mobileMenu(!openMobileMenu);}}>Projects</div>
      <div className="menu-mobile-item" onClick={() => {scrollToElement('team'); mobileMenu(!openMobileMenu);}}>Team</div>
      <div className="menu-mobile-item" onClick={() => {scrollToElement('contact'); mobileMenu(!openMobileMenu);}}>Contact</div>
    </div> */}
    <div className="main-content">
      <div ref={projectRef}>
        <EffectProject />
      </div>
    </div>
    <footer className="footer">
      <div style={{ fontSize: '14px', fontWeight: 400 }}>
        Copyright © 2021. All rights reserved
      </div>
      <div>
        <a href="https://discord.gg/KmdxWqzVBH" target="_blank">
          <img src={Discord} />
        </a>
        <a href="https://twitter.com/blankdotart" target="_blank" style={{ marginLeft: '18px' }}>
          <img src={Twitter} />
        </a>
        <a href="https://www.instagram.com/blankdotart" target="_blank" style={{ marginLeft: '18px' }}>
          <img src={Instagram} />
        </a>
      </div>
    </footer>
    </>
  );
};

export default Home;
