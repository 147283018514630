import React from "react"
import Effect from '../components/Effect';

const IndexPage = () => (
  <>
    <Effect />
  </>
)

export default IndexPage
